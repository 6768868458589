<script>
  export let anim = false;
  export let ball = "poke";
</script>

<div class="{ball} ball {anim}">
	<span class="x">
		<span class="y">
			<span class="sprite">
			</span>
		</span>
	</span>
</div>