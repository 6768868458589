<script>
  import Interface from "./components/Interface.svelte";
</script>

<main>
<Interface /> 
</main>


<style>
  main {
    overflow: hidden;
  }
</style>